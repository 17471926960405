<div
  class="wrapper"
  [appSlideToggle]="visible"
  [appSlideToggleTiming]="animation"
>
  <article
    *ngIf="data"
    [ngClass]="classes"
  >
    <app-icon
      *ngIf="icon"
      [src]="icon"
      alt="icon"
    ></app-icon>
    <p>{{data.message | translate | async }}</p>
    <button
      *ngIf="!data?.duration"
      app-icon-button
      src="assets/images/icons/plus.svg"
      class="close"
      alt="{{ 'Fermer' | translate | async }}"
      (click)="hide()"
    ></button>
  </article>
</div>
