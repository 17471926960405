<span
  class="dot"
  [ngClass]="{ border }"
>
  <ng-container *ngIf="preview2;then bicolor else unicolor">

  </ng-container>
  <ng-template #bicolor>
    <span
      class="bicolor"
      [title]="label | titlecase"
    >
      <span
        class="color preview1"
        [ngStyle]="{backgroundColor: preview1}"
      ></span>
      <span
        class="color preview2"
        [ngStyle]="{backgroundColor: preview2}"
      ></span>
    </span>
  </ng-template>
  <ng-template #unicolor>
    <span
      class="unicolor"
      [title]="label | titlecase"
      [ngStyle]="{backgroundColor: preview1}"
    ></span>
  </ng-template>
</span>
<span class="label" *ngIf="showLabel">{{ label | titlecase }}</span>
