<img
  *ngIf="props"
  [src]="props.src"
  [alt]="props.alt ?? ''"
  [title]="props.title ?? ''"
  loading="lazy"
  [draggable]="draggable"
  [ngClass]="{
    loaded,
    'guess-height': guessHeight,
    'fit': display === 'fit',
    'cover': display === 'cover'
  }"
  [ngStyle]="{
    maxWidth,
    maxHeight
  }"
  (load)="loaded = true"
/>
