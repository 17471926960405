<app-panel-layout
  [title]="('Votre article a bien été ajouté' | translate | async) ?? ''"
  (dismiss)="close()"
>
  <div
    class="content"
    content
  >
    <div class="summary">
      <div class="contact">
        <span class="questions">{{ 'Des questions ?' | translate | async }}</span>
        <a
          [routerLink]="routes.content | route:{ slug: contact?.slug }"
          (click)="close()"
        >{{ 'Contactez notre assistance' | translate | async }}</a>
      </div>
      <div class="price">
        <span class="total">{{ 'Total' | translate | async }} {{ cart | total:0 | price: cart?.currencyCode | async }}</span>
        <span>{{ 'Retours sans frais' | translate | async }}</span>
      </div>
    </div>
    <app-cart-item-card
      *ngIf="data.item"
      [item]="data.item"
      [currency]="cart?.currencyCode"
      [border]="true"
      [disabled]="updating"
      (quantity)="onQuantityChange(data.item, $event)"
    ></app-cart-item-card>
    <app-variants-suggestions
      *ngIf="data.suggestions?.length"
      [suggestions]="data.suggestions"
      [card]="true"
      [disabled]="updating"
      (add)="onAddSuggestion($event)"
    ></app-variants-suggestions>
  </div>
  <div
    class="footer"
    footer
  >
    <div class="x4">
      <span>{{ 'Total' | translate | async }}</span>
      <span
        *ngIf="x4"
        class="from"
        [innerHTML]="('<u>à partir de {x4}/mois</u> ou {total}' | translate: {
          x4: ((cart | subtotal) / 4 | price: cart?.currencyCode | async) ?? '',
          total: (cart | subtotal | price: cart?.currencyCode | async) ?? ''
        } | async) ?? '' | trust:'html'"
      ></span>
      <span
        *ngIf="!x4"
        class="from"
      >{{ cart?.itemsTotal | price: cart?.currencyCode | async }}</span>
    </div>
    <a
      app-button
      [routerLink]="routes.cart | route"
      (click)="close()"
    >{{ 'Voir mon panier' | translate | async }}</a>
  </div>
</app-panel-layout>
