import {
  HTTP_INTERCEPTORS,
  HttpClientJsonpModule,
  HttpClientModule,
} from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import localeDe from '@angular/common/locales/de';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import { APP_ID, ErrorHandler, Injectable, NgModule } from '@angular/core';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
} from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpErrorInterceptor } from './shared/interceptors/http-error.interceptor';
import { QueryStateInterceptor } from './shared/interceptors/query-state.interceptor';
import { SetBearerInterceptor } from './shared/interceptors/set-bearer.interceptor';
import { CatchErrorService } from './shared/services/sys/catch-error.service';
import { SharedModule } from './shared/shared.module';
import { SetLanguageInterceptor } from './shared/interceptors/set-language.interceptor';
import { ClearBearerInterceptor } from './shared/interceptors/clear-bearer.interceptor';
import { registerLocaleData } from '@angular/common';
import { SetChannelInterceptor } from './shared/interceptors/set-channel.interceptor';

@Injectable({ providedIn: 'root' })
export class HammerConfig extends HammerGestureConfig {
  public override overrides: {
    [key: string]: object;
  } = {
    // override hammerjs default configuration
    swipe: { enable: true, direction: 30 },
    pinch: { enable: false },
    rotate: { enable: false },
    pan: { enable: true, direction: 30 },
  };
}

// Supported locales
registerLocaleData(localeEn);
registerLocaleData(localeFr);
registerLocaleData(localeEs);
registerLocaleData(localeDe);
registerLocaleData(localeIt);
registerLocaleData(localeJa);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AppRoutingModule,
    SharedModule,
    HammerModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: CatchErrorService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: QueryStateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClearBearerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetLanguageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetBearerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetChannelInterceptor,
      multi: true,
    },
    { provide: APP_ID, useValue: 'serverApp' },
    { provide: 'googleTagManagerId', useValue: environment.gtmId },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
