<button
  class="button"
  appRipple
  [ngClass]="{ selected, crossed: !option.stock}"
  [disabled]="disabled"
  (click)="onClick()"
>
  <span class="label">{{ option.label }}</span>
</button>
<span
  *ngIf="!option.stock && notification"
  class="notify"
>
  <app-icon
    class="icon"
    src="assets/images/icons/notify.svg"
  ></app-icon>
</span>
