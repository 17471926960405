import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Injectable } from '@angular/core';
import { getBoundingClientRect } from '../../utils/dom.utils';
import { CSSVariables, ResponsiveService } from './responsive.service';

@Injectable({
  providedIn: 'root',
})
export class ScrollerService {
  private _navHeight: number = 0;
  private _pushHeight: number = 0;
  private readonly _window?: Window | null;
  private readonly _history?: History;

  public constructor(
    private readonly _responsive: ResponsiveService,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {
    this._window = this._document.defaultView;
    this._history = this._window?.history;
  }

  public bootstrap(): void {
    this._responsive.variables$.subscribe((variables: CSSVariables) => {
      // We are forced to use numbers here
      this._navHeight = parseInt(variables.navHeight ?? '');
      this._pushHeight = parseInt(variables.pushHeight ?? '');
    });
  }

  public set scrollRestoration(value: 'auto' | 'manual') {
    if (this._history) {
      this._history.scrollRestoration = value;
    }
  }

  public scrollToPosition(position: [number, number], smooth?: boolean): void {
    this._window?.scroll &&
      this._window.scroll({
        top: position[1],
        left: position[0],
        behavior: smooth ? 'smooth' : 'auto',
      });
  }

  public scrollToAnchor(id: string, smooth?: boolean): void {
    const element: HTMLElement | null = this._document.getElementById(id);
    if (element) {
      this.scrollTo(element, smooth);
    }
  }

  public scrollTo(element: HTMLElement, smooth?: boolean): void {
    const elemY: number = getBoundingClientRect(element).top;
    const windowY: number = this._window?.scrollY ?? 0;

    // if we scroll to the top, we assume it will open the header
    let scroll: number = elemY + windowY;
    if (scroll < windowY) {
      scroll -= this._navHeight + this._pushHeight;
    }

    this._window?.scroll &&
      this._window.scroll({
        top: scroll,
        left: 0,
        behavior: smooth ? 'smooth' : 'auto',
      });
  }

  public scrollTop(smooth?: boolean): void {
    this._window?.scroll &&
      this._window.scroll({
        top: 0,
        left: 0,
        behavior: smooth ? 'smooth' : 'auto',
      });
  }

  public scrollIntoView(elementRef?: ElementRef, smooth: boolean = true): void {
    if (elementRef?.nativeElement) {
      elementRef.nativeElement.scrollIntoView({
        behavior: smooth ? 'smooth' : 'instant',
      });
    }
  }
}
