import { Component, Input } from '@angular/core';
import { VariantOption } from 'src/app/shared/models/dto/catalog/variant';

@Component({
  selector: 'app-variant-options',
  templateUrl: './variant-options.component.html',
  styleUrl: './variant-options.component.scss',
})
export class VariantOptionsComponent {
  @Input() public condensed: boolean = false;
  @Input() public options?: {
    [key: string]: VariantOption;
  };
}
