<app-loader
  class="loader"
  [ngClass]="{hidden: !loading}"
></app-loader>
<div class="router">
  <router-outlet></router-outlet>
</div>
<app-snackbar class="snackbar"></app-snackbar>
@if (chat) {
  <button
    app-button
    color="yellow"
    class="chat"
    (click)="startChat()"
  ><app-icon src="assets/images/icons/chat.svg"></app-icon>{{ 'Live chat' | translate | async }}</button>
}
