import { Channel } from '../../models/dto/channel';
import { Network } from '../../models/ui/network';

export const networksMapper = (channel: Channel): Network[] => {
  const networks: Network[] = [];

  channel?.facebook &&
    networks.push({
      label: 'Facebook',
      url: channel?.facebook,
      svg: 'facebook.svg',
    });

  channel?.twitter &&
    networks.push({
      label: 'Twitter',
      url: channel?.twitter,
      svg: 'twitter.svg',
    });

  channel?.instagram &&
    networks.push({
      label: 'Instagram',
      url: channel?.instagram,
      svg: 'instagram.svg',
    });

  channel?.vimeo &&
    networks.push({
      label: 'Vimeo',
      url: channel?.vimeo,
      svg: 'vimeo.svg',
    });

  channel?.youtube &&
    networks.push({
      label: 'Youtube',
      url: channel?.youtube,
      svg: 'youtube.svg',
    });

  channel?.strava &&
    networks.push({
      label: 'Strava',
      url: channel?.strava,
      svg: 'strava.svg',
    });

  return networks;
};
