import { Configuration } from 'src/app/shared/models/sys/configuration';

export const environment: Configuration = {
  baseUrl: 'https://www.mavic.com',
  baseRelPath: './browser',
  apiProtocol: 'https://',
  apiHost: 'api.mavic.com',
  gtmId: 'GTM-5GSMQQQL',
  googleMapsApiKey: 'AIzaSyBYZoy1lJ83PKWGEzN-ECCiW6u97J12YWE',
  tracking: {
    views: true,
    events: true,
  },
};
