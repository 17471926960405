import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Settings } from '../constants/settings.constant';
import { Locale } from '../enums/i18n/locale.enum';
import { StorageKeys } from '../enums/storage-keys.enum';
import { Customer } from '../models/dto/account/customer';
import { Order } from '../models/dto/cart/order';
import { Channel } from '../models/dto/channel';
import { Token } from '../models/dto/token';
import { Privacy } from '../models/sys/privacy';
import { RoutingRequest } from '../models/sys/routing';
import { Header } from '../models/ui/header';
import { Loader } from '../models/ui/loader';
import { channelCode } from '../utils/i18n.utils';
import { ChannelTokensService } from './channel-tokens.service';
import { CookieStorageService } from './storage/cookies-storage.service';
import { SessionStorageService } from './storage/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  private readonly _ssr: boolean;

  private readonly _maintenance$: BehaviorSubject<boolean>;

  private readonly _customer$: BehaviorSubject<Customer | undefined>;
  private readonly _cart$: BehaviorSubject<Order | undefined>;
  private readonly _privacy$: BehaviorSubject<Privacy | undefined>;

  private readonly _loader$: BehaviorSubject<Loader>;
  private readonly _locale$: BehaviorSubject<Locale>;
  private readonly _channel$: BehaviorSubject<Channel | undefined>;
  private readonly _header$: BehaviorSubject<Header>;

  private _redirection?: RoutingRequest;

  private _isReady: boolean = false;
  private readonly _ready: Promise<void>;
  private _resolveReady!: () => void;

  public constructor(
    private readonly _channelTokens: ChannelTokensService,
    private readonly _sessionStorage: SessionStorageService,
    private readonly _cookieStorage: CookieStorageService,
    @Inject(PLATFORM_ID) platformId: object
  ) {
    this._ssr = isPlatformServer(platformId);

    this._maintenance$ = new BehaviorSubject<boolean>(false);

    this._customer$ = new BehaviorSubject<Customer | undefined>(undefined);
    this._cart$ = new BehaviorSubject<Order | undefined>(undefined);
    this._privacy$ = new BehaviorSubject<Privacy | undefined>(undefined);
    this._loader$ = new BehaviorSubject<Loader>({
      queue: 0,
      forced: 0,
    });
    this._locale$ = new BehaviorSubject<Locale>(Settings.defaultLocale);
    this._channel$ = new BehaviorSubject<Channel | undefined>(undefined);
    this._header$ = new BehaviorSubject<Header>({
      overlap: true,
      fixed: false,
    });

    this._ready = new Promise<void>((resolve: () => void) => {
      this._resolveReady = resolve;
    });

    try {
      this._redirection = JSON.parse(
        this._sessionStorage.get(StorageKeys.redirection) as string
      ) as RoutingRequest;
    } catch (e) {
      this._redirection = undefined;
    }
  }

  public get isReady(): boolean {
    return this._isReady;
  }

  public setReady(): void {
    // Async data initialization (if needed)
    this._isReady = true;
    if (this._resolveReady) {
      this._resolveReady();
    }
  }

  public get ready(): Promise<void> {
    return this._ready;
  }

  public get ssr(): boolean {
    return this._ssr;
  }

  public get maintenance(): boolean {
    return this._maintenance$.getValue();
  }

  public set maintenance(value: boolean) {
    this._maintenance$.next(value);
  }

  public get maintenance$(): Observable<boolean> {
    return this._maintenance$.asObservable();
  }

  public get customerToken(): Token | undefined {
    return this._channelTokens.getCustomerToken(channelCode(this.locale));
  }

  public set customerToken(value: Token | undefined) {
    this._channelTokens.setCustomerToken(value, channelCode(this.locale));
  }

  public get customer(): Customer | undefined {
    return this._customer$.getValue();
  }

  public set customer(value: Customer | undefined) {
    this._customer$.next(value);
  }

  public get customer$(): Observable<Customer | undefined> {
    return this._customer$.asObservable();
  }

  public get cartToken(): string | undefined {
    return this._channelTokens.getCartToken(channelCode(this.locale));
  }

  public set cartToken(value: string | undefined) {
    this._channelTokens.setCartToken(value, channelCode(this.locale));
  }

  public get cart(): Order | undefined {
    return this._cart$.getValue();
  }

  public set cart(value: Order | undefined) {
    if (value?.tokenValue !== this.cartToken) {
      this.cartToken = value?.tokenValue;
    }

    this._cart$.next(value);
  }

  public get cart$(): Observable<Order | undefined> {
    return this._cart$.asObservable();
  }

  public get privacy(): Privacy | undefined {
    return this._privacy$.getValue();
  }

  public set privacy(value: Privacy | undefined) {
    this._privacy$.next(value);
  }

  public get privacy$(): Observable<Privacy | undefined> {
    return this._privacy$.asObservable();
  }

  public get loader(): Loader {
    return this._loader$.getValue();
  }

  public set loader(value: Loader) {
    this._loader$.next(value);
  }

  public get loader$(): Observable<Loader> {
    return this._loader$.asObservable();
  }

  public get locale(): Locale {
    return this._locale$.getValue();
  }

  public set locale(value: Locale) {
    if (value) {
      this._cookieStorage.set(StorageKeys.userLocale, value);
    } else {
      this._cookieStorage.remove(StorageKeys.userLocale);
    }

    this._locale$.next(value);
  }

  public get locale$(): Observable<Locale> {
    return this._locale$.asObservable();
  }

  public get channel(): Channel | undefined {
    return this._channel$.getValue();
  }

  public set channel(value: Channel | undefined) {
    this._channel$.next(value);
  }

  public get channel$(): Observable<Channel | undefined> {
    return this._channel$.asObservable();
  }

  public get header(): Header {
    return this._header$.getValue();
  }

  public set header(value: Header) {
    this._header$.next(value);
  }

  public get header$(): Observable<Header> {
    return this._header$.asObservable();
  }

  public get redirection(): RoutingRequest | undefined {
    return this._redirection;
  }

  public set redirection(value: RoutingRequest | undefined) {
    if (value) {
      this._sessionStorage.set(StorageKeys.redirection, JSON.stringify(value));
    } else {
      this._sessionStorage.remove(StorageKeys.redirection);
    }

    this._redirection = value;
  }

  public get orderCompletedToken(): string | undefined {
    return this._channelTokens.getOrderCompleteToken(channelCode(this.locale));
  }

  public set orderCompletedToken(value: string | undefined) {
    this._channelTokens.setOrderCompleteToken(value, channelCode(this.locale));
  }

  public logout(): void {
    this.customerToken = undefined;
    this.orderCompletedToken = undefined;
    this.customer = undefined;
  }

  public queueLoad(resolved: boolean = false): void {
    this.loader = {
      ...this.loader,
      forced: resolved ? this.loader.forced - 1 : this.loader.forced + 1,
    };
  }

  public fakeLoad(time: number = 250): void {
    this.loader = {
      ...this.loader,
      forced: this.loader.forced + 1,
    };

    setTimeout(() => {
      this.loader = {
        ...this.loader,
        forced: this.loader.forced - 1,
      };
    }, Math.random() * 100 + time);
  }
}
