import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Currency } from 'src/app/shared/enums/i18n/currency.enum';
import { OrderItem } from 'src/app/shared/models/dto/cart/order';
import { AbstractComponent } from '../../abstract.component';
import { Price } from 'src/app/shared/models/dto/catalog/price';

@Component({
  selector: 'app-cart-item-card',
  templateUrl: './cart-item-card.component.html',
  styleUrls: ['./cart-item-card.component.scss'],
})
export class CartItemCardComponent
  extends AbstractComponent
  implements OnChanges
{
  @Input({ required: true }) public item?: OrderItem;
  @Input({ required: true }) public currency?: Currency;
  @Input() public disabled: boolean = false;
  @Input() public border: boolean = false;

  @Output() public readonly quantity: EventEmitter<number>;

  public current?: number;
  public max?: number;

  public price?: Price;

  public constructor() {
    super();
    this.quantity = new EventEmitter<number>();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.current = this.item?.quantity;
    this.max =
      (this.item?.variant?.stock ?? 0) > 0
        ? this.item?.variant?.stock
        : undefined;

    this.price = this.item
      ? {
          price: this.item.subtotal,
          original_price:
            (this.item.originalUnitPrice ?? 0) * (this.item.quantity ?? 1),
          currency: this.currency,
        }
      : undefined;
  }

  public setQuantity(i: number): void {
    this.current = i;
    this.quantity.emit(i);
  }
}
