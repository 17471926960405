<div
  *ngIf="suggestions?.length"
  class="suggestions"
  [ngClass]="{ card }"
>
  <span class="discover">{{ 'Découvrez nos recommandations' | translate | async }}</span>
  <app-suggestion-card
    *ngFor="let variant of suggestions"
    [variant]="variant"
    [disabled]="disabled"
    [grey]="!card"
  ></app-suggestion-card>
</div>
