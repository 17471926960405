import { ViewportScroller } from '@angular/common';
import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Scroll } from '@angular/router';
import { filter, map, mergeMap, of } from 'rxjs';
import { StructuredDataKeys } from './shared/enums/structured-data-keys.enum';
import { Metadata } from './shared/models/sys/metadata';
import { Schema } from './shared/models/sys/schema-org';
import { Loader } from './shared/models/ui/loader';
import { AppRouterService } from './shared/services/app-router.service';
import { AppStateService } from './shared/services/app-state.service';
import { ResponsiveService } from './shared/services/html/responsive.service';
import { ScrollerService } from './shared/services/html/scroller.service';
import { SeoService } from './shared/services/html/seo.service';
import { StructuredDataService } from './shared/services/html/structured-data.service';
import { ChatService } from './shared/services/libs/chat.service';
import { TagManagerService } from './shared/services/tms/tag-manager.service';
import { TrackerService } from './shared/services/tms/tracker.service';
import { nextFrame } from './shared/utils/rxjs.utils';
import { getProp } from './shared/utils/typing.utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public loading: boolean = true;
  public chat: boolean = false;

  public constructor(
    private readonly _appState: AppStateService,
    private readonly _appRouter: AppRouterService,
    private readonly _responsive: ResponsiveService,
    private readonly _seo: SeoService,
    private readonly _tagManager: TagManagerService,
    private readonly _tracker: TrackerService,
    private readonly _structuredData: StructuredDataService,
    private readonly _scroller: ScrollerService,
    private readonly _chat: ChatService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _ngZone: NgZone,
    private readonly _viewportScroller: ViewportScroller
  ) {}

  public ngOnInit(): void {
    this._responsive.bootstrap();
    this._scroller.bootstrap();
    this._seo.bootstrap();
    this._tagManager.bootstrap();
    this._structuredData.bootstrap();
    this._chat.bootstrap();

    this.observeState();
    this.observeNavigation();

    this._chat.active$.subscribe((active: boolean) => {
      this.chat = active;
    });
  }

  private observeState(): void {
    // Update loader
    this._appState.loader$.pipe(nextFrame()).subscribe((loader: Loader) =>
      this._ngZone.run(() => {
        // Sometime we lose angular scope ¯\_(ツ)_/¯
        this.loading = !!(loader.queue || loader.forced);
      })
    );
  }

  private observeNavigation(): void {
    this._viewportScroller.setHistoryScrollRestoration('manual');
    this._appRouter.navigationScroll$.subscribe((scroll: Scroll) => {
      if (scroll.position) {
        // history navigation
        this._scroller.scrollToPosition(scroll.position, true);
      } else if (scroll.anchor) {
        // anchor navigation
        this._scroller.scrollToAnchor(scroll.anchor, true);
      } else {
        // forward navigation
        this._scroller.scrollTop(true);
      }
    });

    // Overlap update on route change
    this._appRouter.navigationEnd$
      .pipe(
        map(() => {
          let route: ActivatedRoute | null = this._activatedRoute.firstChild;
          while (route?.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route: ActivatedRoute | null) => route?.outlet === 'primary'),
        mergeMap((route: ActivatedRoute | null) =>
          route ? route.data : of(null)
        )
      )
      .subscribe((data?: unknown) => {
        this._appState.header = {
          ...this._appState.header,
          overlap: !!getProp<boolean>(data, 'overlap'),
        };
      });

    // We finally listen pour page load to track events
    let schemas: { [key in StructuredDataKeys]?: Schema } | undefined;
    this._appRouter.metadata$.subscribe((metadata?: Metadata) => {
      this._seo.title = metadata?.title;
      this._seo.description = metadata?.description;
      this._seo.keywords = metadata?.keywords;
      this._seo.author = metadata?.author;
      this._seo.image = metadata?.image;
      this._seo.robots = metadata?.robots;
      this._seo.setAlternateLinks(metadata?.links ?? []);
      this._seo.setCanonicalURL(metadata?.canonical);

      // Update the page structured data
      Object.keys(metadata?.schemas ?? {}).forEach((value: string) => {
        const key: StructuredDataKeys = value as unknown as StructuredDataKeys;
        if (metadata?.schemas?.[key]) {
          this._structuredData.set(key, metadata.schemas[key] as Schema);
        }
      });

      // Cleanup the missing old one
      Object.keys(schemas ?? {}).forEach((value: string) => {
        const key: StructuredDataKeys | undefined = !Object.keys(
          metadata?.schemas ?? {}
        ).includes(value)
          ? (value as unknown as StructuredDataKeys)
          : undefined;

        if (key) {
          this._structuredData.delete(key);
        }
      });
      schemas = metadata?.schemas;

      if (metadata) {
        this._tracker.trackPage();
        this._tracker.trackPreload(metadata.type);
      }
    });
  }

  public startChat(): void {
    this._chat.toggle();
  }
}
