<article
  *ngIf="item"
  [ngClass]="{ border }"
>
  <div class="inner">
    <a
      class="link"
      [routerLink]="routes.product | route:{ slug: item.variant?.slug }"
      [queryParams]="{ variant: item.variant?.variant_id }"
    >
      <app-image
        *ngIf="item.variant && item.variant.images && item.variant.images[0]"
        class="image"
        [image]="item.variant.images[0]"
        display="fit"
      ></app-image>
    </a>
    <div class="details">
      <header>
        <span>{{ item.productName }}</span>
        <app-price
          class="price"
          [price]="price"
          [reverse]="true"
        ></app-price>
      </header>
      <div class="controls">
        <app-number-stepper
          [disabled]="disabled || !max"
          [ngModel]="current"
          [max]="max"
          [forceValue]="true"
          (ngModelChange)="setQuantity($event)"
        ></app-number-stepper>
        <button
          app-icon-button
          src="assets/images/icons/trash.svg"
          preset="light"
          [disabled]="disabled"
          [alt]="('Supprimer' | translate | async) ?? ''"
          (click)="setQuantity(0)"
        ></button>
      </div>
      <span
        class="error"
        *ngIf="item?.variant?.stock && (item.variant?.stock ?? 0) <= 0"
      >
        {{ 'Ce produit n\'est plus disponible.' | translate | async }}
      </span>
      <span
        class="error"
        *ngIf="item?.variant?.stock && (item.variant?.stock ?? 0) > 0 && (item.variant?.stock ?? 0) < (item.quantity ?? 0)"
      >
        {{ 'Seulement {count} pièce(s) sont disponible(s).' | translate: {count: item.variant?.stock ?? 0} | async }}
      </span>
    </div>
  </div>
  <app-variant-options [options]="item.variant?.options" />
</article>
