import { Pipe, PipeTransform } from '@angular/core';
import { VariantOption } from '../models/dto/catalog/variant';
import { Color } from '../models/ui/color';

@Pipe({
  name: 'optionToColor',
})
export class OptionToColorPipe implements PipeTransform {
  /**
   * Convert an option to a color
   */
  public transform(option: VariantOption): Color {
    return {
      hexa: option?.hexa ?? '#fff',
      label: option.title,
    };
  }
}
