<ul class="wrapper">
  <li *ngFor="let option of options">
    <app-variant-option
      [option]="option"
      [selected]="option === value"
      [disabled]="disabled"
      [notification]="notification"
      (toggle)="onToggle(option)"
      (notify)="onNotify(option)"
    ></app-variant-option>
  </li>
</ul>
