<article
  *ngIf="variant"
  [ngClass]="{ grey }"
>
  <a [routerLink]="routes.product | route: { slug: variant.slug }">
    <app-image
      *ngIf="variant.images && variant.images.length"
      class="image"
      [image]="variant.images[0]"
      display="fit"
    ></app-image>
  </a>
  <div class="inner">
    <header>
      <span>{{ variant.title }}</span>
      <app-price
        [price]="variant.price"
        [reverse]="true"
      ></app-price>
    </header>
    <a
      app-button
      color="yellow"
      class="button"
      [routerLink]="routes.product | route: { slug: variant.slug }"
      [condensed]="true"
    >{{ 'Voir' | translate | async }}</a>
  </div>
</article>
