<div
  class="wrapper"
  *ngIf="price?.price"
  [ngClass]="{ promo: price?.original_price && price?.original_price !== price?.price , condensed, reverse }"
>
  <span class="value">
    <span
      *ngIf="price?.original_price && price?.original_price !== price?.price"
      class="original"
    >{{ price?.original_price | price:price?.currency | async }}</span>
    <span class="current">{{ price?.price | price:price?.currency | async }}</span>
  </span>
  <span
    *ngIf="price?.original_price && price?.original_price !== price?.price"
    class="reduction"
  >{{ price?.price | promo:price?.original_price}}</span>
</div>
