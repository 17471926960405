import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { combineLatest, takeUntil } from 'rxjs';
import { Order, OrderItem } from 'src/app/shared/models/dto/cart/order';
import { Variant } from 'src/app/shared/models/dto/catalog/variant';
import { Channel, ChannelLink } from 'src/app/shared/models/dto/channel';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { filterSuggestions } from 'src/app/shared/utils/product.utils';
import { DialogComponent } from '../dialog.component';
import { AppRouterService } from '../../../services/app-router.service';
import { subtotal } from 'src/app/shared/utils/order.utils';

export interface CartCtaDialogData {
  item?: OrderItem;
  suggestions?: Variant[];
}

@Component({
  selector: 'app-cart-cta-dialog',
  templateUrl: './cart-cta-dialog.component.html',
  styleUrls: ['./cart-cta-dialog.component.scss'],
})
export class CartCtaDialogComponent
  extends DialogComponent<void>
  implements OnInit
{
  public cart?: Order;
  public updating: boolean = false;

  public x4: boolean = false;

  public contact?: ChannelLink;

  public constructor(
    dialogRef: DialogRef<void>,
    @Inject(DIALOG_DATA) public data: CartCtaDialogData,
    private readonly _appState: AppStateService,
    private readonly _cart: CartService,
    private readonly _snackbar: SnackbarService,
    private readonly _router: AppRouterService
  ) {
    super(dialogRef);
  }

  public ngOnInit(): void {
    combineLatest([this._appState.cart$, this._appState.channel$])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
        ([cart, channel]: [Order | undefined, Channel | undefined]) => {
          this.cart = cart;
          this.x4 =
            subtotal(cart) > (channel?.adyen_alma_min ?? 0) &&
            (!channel?.adyen_alma_max ||
              subtotal(cart) < (channel?.adyen_alma_max ?? 0));

          this.contact = channel?.contact;
        }
      );

    // Close call to action when route change
    this._router.navigationEnd$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        this.close();
      });
  }

  public onQuantityChange(item: OrderItem, quantity: number): void {
    if (item?.id && quantity !== item?.quantity) {
      this.updating = true;
      this._cart.setQuantity(quantity, item.id).subscribe({
        next: (order: Order | null) => {
          this.updating = false;
          this.data = {
            ...this.data,
            item: order?.items?.find(
              (i: OrderItem) => i.id === this.data.item?.id
            ),
          };

          if (!quantity) {
            this.close();
          }
        },
        error: () => {
          this._snackbar.error(
            'Une erreur est survenue lors de la modification de votre panier.'
          );
          this.updating = false;
        },
      });
    }
  }

  public onAddSuggestion(variant: Variant): void {
    if (variant?.code) {
      this.updating = true;
      this._cart
        .addItem(
          variant?.code,
          true,
          variant.variant_id,
          filterSuggestions(this._appState.channel?.products_cart, [variant])
        )
        .subscribe({
          next: (order: Order | null) => {
            this.updating = false;
            this.close();
          },
          error: () => {
            this._snackbar.error(
              "Une erreur est survenue lors de l'ajout à votre panier."
            );
            this.updating = false;
          },
        });
    }
  }
}
