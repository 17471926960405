import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Image } from 'src/app/shared/models/dto/image';
import { AbstractComponent } from '../../abstract.component';

interface ImageProps {
  src: string;
  alt?: string;
  title?: string;
}

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent extends AbstractComponent implements OnChanges {
  @Input({ required: true }) public image?: Image | string;
  @Input() public display?: 'fit' | 'cover';
  @Input() public alt?: string;
  @Input() public title?: string;
  @Input() public draggable: boolean = true;
  @Input() public usePath: boolean = false; // obviously only works if this.image is an Image

  @Input() public guessHeight: boolean = true;

  @Input() public maxWidth?: string;
  @Input() public maxHeight?: string;

  public loaded: boolean = false;

  public props?: ImageProps;

  public ngOnChanges(changes: SimpleChanges): void {
    let props: ImageProps;
    if (typeof this.image === 'string') {
      props = {
        src: this.image,
        alt: this.alt,
        title: this.title,
      };
    } else {
      props = {
        src: (this.usePath ? this.image?.path : this.image?.cachedPath) ?? '',
        alt: this.alt ?? this.image?.alt,
        title: this.title ?? this.image?.title,
      };
    }

    // We check the difs to avoid flicker in case the ref change but the image stay the same
    if (
      props.src !== this.props?.src ||
      props.alt !== this.props?.alt ||
      props.title !== this.props?.title
    ) {
      this.props = props;

      // We reset the loading state if the source change
      if (props.src !== this.props?.src) {
        this.loaded = false;
      }
    }
  }
}
